import React from 'react';
import { Form } from 'informed';
import useTranslate from '../../../../hooks/useTranslate';
import StepperButtons from '../../components/StepperButtons';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import ReviewAndSignText from '../../i18n/ReviewAndSignText';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import SelectedCoverages from './components/review/SelectedCoverages';
import EmploymentInformation from './components/review/EmploymentInformation';
import PersonalInformation from './components/review/PersonalInformation';
import ContactInformation from './components/review/ContactInformation';
import ApplicantContainer from '../../components/ApplicantContainer';
import { ApplicationReasonText } from '../../i18n/EoiVerificationText';
import useEoiContext from '../../hooks/useEoiContext';
import MedicalQuestions from './components/review/MedicalQuestions';
import { hasCIEmployeeCoverageSelected, hasCISpouseCoverageSelected, sortCoverages } from '../../utils/HelperFunctions';
import SaveForLaterErrorAlert from '../../components/SaveForLaterErrorAlert';
import ReflexiveQuestions from './components/review/ReflexiveQuestions';
import MedicalQuestionAnswerSection from './components/review/MedicalQuestionAnswerSection';
import { EoiGeneralText } from '../../i18n/EoiText';
import { Box } from '@digitools/honest-react';
import LegalFormNumbers from '../../components/LegalFormNumbers';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
const getAllCoverages = (applicant, interviewAnswers) => {
    const familyCoverages = interviewAnswers.coverageElections && interviewAnswers.coverageElections.familyCoverage
        ? interviewAnswers.coverageElections.familyCoverage
        : [];
    if (applicant === 'employee') {
        if (interviewAnswers.coverageElections &&
            interviewAnswers.coverageElections.employeeCoverage &&
            interviewAnswers.applicantTypes &&
            interviewAnswers.applicantTypes.employee) {
            return [...interviewAnswers.coverageElections.employeeCoverage];
        }
    }
    else if (applicant === 'spouse') {
        if (interviewAnswers.coverageElections &&
            interviewAnswers.coverageElections.spouseCoverage &&
            interviewAnswers.applicantTypes &&
            interviewAnswers.applicantTypes.spouse) {
            return [...interviewAnswers.coverageElections.spouseCoverage, ...familyCoverages];
        }
    }
    else if (applicant === 'dependent') {
        if (interviewAnswers.coverageElections &&
            interviewAnswers.coverageElections.dependentCoverage &&
            interviewAnswers.applicantTypes &&
            interviewAnswers.applicantTypes.dependent) {
            return [...interviewAnswers.coverageElections.dependentCoverage, ...familyCoverages];
        }
    }
    return [];
};
const Review = (props) => {
    const { t } = useTranslate();
    const { interviewAnswers, setDisplaySaveForLaterError, setDisplaySaveForLaterModal, initialInformation } = useEoiContext();
    const { trackEvent } = useTealium();
    const submit = () => {
        setDisplaySaveForLaterError(false);
        setDisplaySaveForLaterModal(false);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EOI,
            event_name: TEALIUM_EVENT.EVENT_NAME.SAVE_AND_CONTINUE,
            event_version: TEALIUM_EVENT.EVENT_VERSION.REVIEW,
            event_status: 'step 5'
        });
        props.nextStep();
    };
    const getApplicationReason = () => {
        if (interviewAnswers.additionalInformation) {
            if (interviewAnswers.additionalInformation.newEmployee &&
                interviewAnswers.additionalInformation.newEmployee === 'true') {
                return (<strong>
            <em>{t(ApplicationReasonText.newHire)}</em>
          </strong>);
            }
            else if (interviewAnswers.additionalInformation.applicationReason) {
                let reason;
                switch (interviewAnswers.additionalInformation.applicationReason) {
                    case 'EELE':
                        reason = t(ApplicationReasonText.lateEnrollment);
                        break;
                    case 'LFSC':
                        reason = t(ApplicationReasonText.familyStatusChange);
                        break;
                    case 'SALI':
                        reason = t(ApplicationReasonText.salaryIncrease);
                        break;
                    case 'EEAE':
                        reason = t(ApplicationReasonText.annualEnrollmentIndicator);
                        break;
                    case 'NENE':
                        reason = t(ApplicationReasonText.initialEnrollment);
                        break;
                }
                return (<strong>
            <em>{reason}</em>
          </strong>);
            }
            else {
                return <></>;
            }
        }
    };
    let displayCriticalIllnessQuestions = false;
    if (interviewAnswers &&
        hasCIEmployeeCoverageSelected(interviewAnswers) &&
        interviewAnswers.criticalIllnessAnswers &&
        interviewAnswers.criticalIllnessAnswers.length > 0) {
        displayCriticalIllnessQuestions = true;
    }
    else {
        if (interviewAnswers &&
            hasCISpouseCoverageSelected(interviewAnswers) &&
            interviewAnswers.criticalIllnessAnswers &&
            interviewAnswers.criticalIllnessAnswers.length > 0) {
            displayCriticalIllnessQuestions = true;
        }
    }
    let criticalIllnessOnlyQuestions = [];
    if (initialInformation && displayCriticalIllnessQuestions) {
        initialInformation.criticalIllnessOnlyQuestions.forEach(question => {
            const text = question.text;
            const interviewAnswer = interviewAnswers
                .criticalIllnessAnswers.filter(criticalIllnessAnswer => {
                return question.questionCode === criticalIllnessAnswer.questionCode && 'EMPTY' === criticalIllnessAnswer.role;
            })
                .shift();
            const answer = interviewAnswer && interviewAnswer.value ? EoiGeneralText.yes : EoiGeneralText.no;
            criticalIllnessOnlyQuestions.push({ text, answer });
        });
    }
    return (<Form onSubmit={submit} data-public={true}>
      <Heading elemStyle={'h1'} elemType={'h1'} theme={themes.lincoln} color={'primary'} data-testid={'review-heading'}>
        {t(ReviewAndSignText.reviewYourApplication)}
      </Heading>
      <p>
        {t(ReviewAndSignText.applicationReason)} {getApplicationReason()}
      </p>
      <span>{t(ReviewAndSignText.generalInfo)}</span>
      <p>{t(ReviewAndSignText.updateInstructions)}</p>

      {/* Employee */}
      {interviewAnswers && interviewAnswers.applicants && interviewAnswers.applicants.employee && (<ApplicantContainer applicant={'employee'} type={'name'} firstName={interviewAnswers.applicants.employee.firstName} lastName={interviewAnswers.applicants.employee.lastName}>
          {getAllCoverages('employee', interviewAnswers).length > 0 && (<SelectedCoverages applicant={'employee'} coverages={sortCoverages(getAllCoverages('employee', interviewAnswers))}/>)}
          <EmploymentInformation />
          <PersonalInformation applicant={interviewAnswers.applicants.employee} displayApplicantFields={interviewAnswers.applicantTypes.employee}/>
          <ContactInformation />
          {interviewAnswers.applicantTypes.employee && <MedicalQuestions applicantType={'employee'}/>}
          <ReflexiveQuestions applicantType={'employee'}/>
        </ApplicantContainer>)}

      {/* Spouse */}
      {interviewAnswers.applicants && interviewAnswers.applicants.spouse && (<ApplicantContainer applicant={'spouse'} type={'name'} firstName={interviewAnswers.applicants.spouse.firstName} lastName={interviewAnswers.applicants.spouse.lastName}>
          {getAllCoverages('spouse', interviewAnswers).length > 0 && (<SelectedCoverages applicant={'spouse'} coverages={sortCoverages(getAllCoverages('spouse', interviewAnswers))}/>)}
          <PersonalInformation applicant={interviewAnswers.applicants.spouse} displayApplicantFields={true}/>
          <MedicalQuestions applicantType={'spouse'}/>
          <ReflexiveQuestions applicantType={'spouse'}/>
        </ApplicantContainer>)}

      {/* Dependents */}
      {interviewAnswers.applicants &&
            interviewAnswers.applicants.dependents &&
            interviewAnswers.applicants.dependents.map((dependent, i) => {
                return (<ApplicantContainer applicant={'dependent'} type={'name'} firstName={dependent.firstName} lastName={dependent.lastName} key={i}>
              {getAllCoverages('dependent', interviewAnswers).length > 0 && (<SelectedCoverages applicant={'dependent'} coverages={sortCoverages(getAllCoverages('dependent', interviewAnswers))}/>)}
              <PersonalInformation applicant={dependent} displayApplicantFields={true}/>
              <MedicalQuestions applicantType={'dependent'} dependentId={dependent.dependentId}/>
              <ReflexiveQuestions applicantType={'dependent'} dependentId={dependent.dependentId}/>
            </ApplicantContainer>);
            })}

      {criticalIllnessOnlyQuestions.map((question, i) => {
            return (<Box style={{ border: 'none' }}>
            <MedicalQuestionAnswerSection dataTestid={`all-critical`} key={i} questionText={question.text} questionAnswer={question.answer}/>
          </Box>);
        })}

      <SaveForLaterErrorAlert />
      <LegalFormNumbers showSupplementalForm={interviewAnswers.reflexiveAnswers && interviewAnswers.reflexiveAnswers.employee !== undefined}/>
      <StepperButtons continueButtonType={'continue'} page={'/customer/eoi/review'} analyticsPageName={'review'}/>
    </Form>);
};
export default Review;

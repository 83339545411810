import React, { useContext } from 'react';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import { StepperContext } from '@digitools/honest-react/dist/lib/components/Stepper/StepperContext';
import useTranslate from '../../../hooks/useTranslate';
import { EoiGeneralText } from '../i18n/EoiText';
import useEoiContext from '../hooks/useEoiContext';
import SaveForLaterButtons from './SaveForLaterButtons';
import styled from 'styled-components';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';
const StyledDiv = styled('div') `
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @media(max-width: 765px) {
        flex-direction: column-reverse;
    }
`;
const StyledContinueButton = styled(Button) `
    margin-left: 0 !important;
    @media(max-width: 765px) {
        margin-bottom: 0.5rem;
    }
`;
const StyledBackButton = styled(Button) `
    @media(max-width: 765px) {
        margin-top: 0.5rem;
    }
`;
const BackButton = (props) => {
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const { trackClickWithPage } = useGoogleAnalytics();
    const onClick = () => {
        trackClickWithPage('back', props.page);
        props.onClick ? props.onClick() : stepper.back();
    };
    return (<StyledBackButton type={'button'} buttonType={'secondary'} theme={themes.lincoln} onClick={() => onClick()}>
            {t(EoiGeneralText.back)}
        </StyledBackButton>);
};
const ContinueButton = (props) => {
    const { t } = useTranslate();
    const { customerConfig } = useEoiContext();
    const label = props.type === 'continue' ? (customerConfig && customerConfig.saveForLater ? t(EoiGeneralText.saveAndContinue) : t(EoiGeneralText.continue)) : t(EoiGeneralText.submit);
    return (<StyledContinueButton type={'submit'} buttonType={'primary'} theme={themes.lincoln} data-testid={'continue-button'}>
            {label}
        </StyledContinueButton>);
};
const StepperButtons = (props) => {
    const { customerConfig } = useEoiContext();
    return (<StyledDiv>
            <BackButton onClick={props.backButtonOnClick} page={props.page}/>
            {customerConfig && customerConfig.saveForLater &&
            <SaveForLaterButtons saveToProvider={props.saveToProvider} page={props.page} analyticsPageName={props.analyticsPageName}/>}
            <ContinueButton type={props.continueButtonType}/>
        </StyledDiv>);
};
export default StepperButtons;

import { EoiGeneralText } from "../i18n/EoiText";
export const getEventStatusByCurrentStep = (stepper, t, initialInformation, analyticsPageName) => {
    if (stepper.currentStep === t(EoiGeneralText.applicantCoverageStep))
        return 'step 2';
    if (stepper.currentStep === t(EoiGeneralText.applicantInfoStep))
        return 'step 3';
    if (stepper.currentStep === (initialInformation?.employerConfiguration.situsStateCode.toUpperCase() === 'NY' ? t(EoiGeneralText.statementOfHealthStep) : t(EoiGeneralText.qualifyingQuestionsStep)))
        return 'step 4';
    if (stepper.currentStep === (initialInformation?.employerConfiguration.situsStateCode.toUpperCase() === 'NY' ? t(EoiGeneralText.reflexiveStatementOfHealthStep) : t(EoiGeneralText.reflexiveQualifyingQuestionsStep)))
        return 'step 4';
    if (analyticsPageName === 'review')
        return 'step 5';
    if (analyticsPageName === 'submit')
        return 'step 6';
};

import React, { useLayoutEffect } from 'react';
import useTranslate from '../../../../hooks/useTranslate';
import EoiVerificationText from '../../i18n/EoiVerificationText';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import useAuthentication from '../../../../hooks/useAuthentication';
import IdentityVerificationForm from './IdentityVerificationForm';
import QuickTipsBox from '../../components/QuickTipsBox';
import { GetCustomContent, GetInitialInformation, GetInterviewData, } from '../../api/EoiApiCalls';
import AdditionalInfoForm from './AdditionalInfoForm';
import useEoiContext from '../../hooks/useEoiContext';
import LoadSavedInterview from './LoadSavedInterview';
import { customize } from '../../utils/HelperFunctions';
import { Spinner } from '@digitools/honest-react';
const EoiVerification = ({ setIsVerified, isVerified, setIsOnGettingStartedPage, configData, }) => {
    const { t } = useTranslate();
    const { setInitialInformation, setEoiError, setCustomContent, savedInterview } = useEoiContext();
    const { user } = useAuthentication();
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    // add some state object
    const firstName = user?.firstName || '';
    const { loading: initialInformationLoading, data: initialInformationData, error: initialInformationError, } = GetInitialInformation();
    const { loading: customContentLoading, data: customContentData, error: customContentError, } = GetCustomContent();
    const { loading: savedInterviewLoading, data: savedInterviewData } = configData && configData.saveForLater
        ? GetInterviewData()
        : {
            loading: false,
            data: undefined,
            error: undefined,
        };
    const ssoUserEmpId = user && user.ssoUser ? user.ssoCargo.employeeId : undefined;
    if (initialInformationError || customContentError) {
        setEoiError(true);
    }
    if (initialInformationData) {
        initialInformationData.medicalQuestions.forEach(data => {
            //@ts-ignore
            data.questionCode = data.medicalFactorQuestionCode;
        });
        setInitialInformation(initialInformationData);
    }
    if (customContentData) {
        setCustomContent(customContentData);
    }
    return (<div data-testid='eoi-verification'>
      {customContentLoading && (<div style={{ margin: 'auto', marginBottom: '1rem' }}>
          <Spinner id='eoi-verification-spinner1'/>
        </div>)}
      {customContentData && (<>
          {firstName && (<Heading elemType={'h2'} color={'primary'} theme={themes.lincoln} data-testid={'welcome-heading'} style={{ marginBottom: '1.875rem', marginTop: '1.275rem' }}>
              {t(EoiVerificationText.welcomeHeading)} {firstName}
            </Heading>)}
          {configData.overviewTitle && (<Heading data-testid='eoi-config-overview-heading' elemType={'h4'} elemStyle={'h4'} style={{ marginBottom: '0', marginTop: '0' }}>
              {t(configData.overviewTitle)}
            </Heading>)}
          {configData.overviewBody && <p data-testid='eoi-config-overview-body'>{t(configData.overviewBody)}</p>}
          <p data-testid='eoi-config-standard-text'>
            {customContentData &&
                t(customize(EoiVerificationText.standardText, customContentData, initialInformationData, true))}
          </p>
        </>)}
      <QuickTipsBox data-public={true}/>
      <br />
      {(initialInformationLoading || savedInterviewLoading || savedInterview === undefined) && (<div style={{ margin: 'auto' }}>
          <Spinner id='eoi-verification-spinner2' data-public={true}/>
        </div>)}

      {savedInterview === false && !initialInformationLoading && savedInterviewData ? (<LoadSavedInterview savedInterview={savedInterviewData} setIsVerified={setIsVerified}/>) : (initialInformationData &&
            !savedInterviewLoading &&
            savedInterview !== undefined &&
            <div data-testid='eoi-verification-container'>
            <IdentityVerificationForm setIsVerified={setIsVerified} ssoUserEmpId={ssoUserEmpId}/>
            {isVerified && <AdditionalInfoForm setIsOnGettingStartedPage={setIsOnGettingStartedPage}/>}
          </div>)}
    </div>);
};
export default EoiVerification;

import Box from '@digitools/honest-react/dist/lib/components/Box';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import React from 'react';
import styled from 'styled-components';
import useAuthentication from '../../../../../hooks/useAuthentication';
import useExperience from '../../../../../hooks/useExperience';
import useGoogleAnalytics from '../../../../../hooks/useGoogleAnalytics';
import useTranslate from '../../../../../hooks/useTranslate';
import { downloadConfirmationPdf } from '../../../api/EoiApiCalls';
import useEoiContext from '../../../hooks/useEoiContext';
import { ConfirmationText } from '../../../i18n/ConfirmationText';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
const ViewAndPrintBox = styled(Box) `
  margin-bottom: 1rem;
  margin-top: 1.875rem;
  width: 425px;
  background-color: ${themes.lincoln.palette.grayLightest};
  margin-left: 1rem;
  border: none;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    width: 100%;
    margin-left: 0;
  }
`;
const downloadConfirmation = (submission, applicationId, setErrorMessage, authHeader, language, errorMessage) => {
    downloadConfirmationPdf(authHeader, language, submission, applicationId, setErrorMessage, errorMessage);
};
const DownloadAndPrint = (props) => {
    const { t } = useTranslate();
    const { language } = useExperience();
    const { authHeader } = useAuthentication();
    const { trackEvent } = useTealium();
    const { questionSubmissionResponse, interviewAnswers, initialInformation, customContent, legalText, reflexiveQuestions, } = useEoiContext();
    const { trackClickWithPage } = useGoogleAnalytics();
    if (interviewAnswers && initialInformation && customContent && legalText) {
        const idOrLabel = questionSubmissionResponse && questionSubmissionResponse.applicationId ? questionSubmissionResponse.applicationId : `${new Date().getTime()}`;
        const submission = {
            ...interviewAnswers,
            initialInformation,
            applicationId: idOrLabel,
            customContent,
            reflexiveQuestions,
            legalText,
        };
        const handleOnClick = async () => {
            trackEvent({
                event_action: TEALIUM_EVENT.EVENT_ACTION.DOWNLOAD,
                event_type: TEALIUM_EVENT.EVENT_TYPE.EOI,
                event_name: 'view and print my application',
                event_version: TEALIUM_EVENT.EVENT_VERSION.CONFIRMATION
            });
            downloadConfirmation(submission, idOrLabel, props.setErrorMessage, authHeader(), language, t(ConfirmationText.downloadError));
        };
        return (<ViewAndPrintBox data-testid={'download-and-print'}>
        <Button theme={themes.lincoln} type={'button'} style={{ fontSize: '1.5rem' }} onClick={handleOnClick} data-testid={'download-button'}>
          <Icon name={'pdf'} size='medium' color={'secondary'} style={{ marginRight: '0.5rem' }}/>
          {t(ConfirmationText.viewAndPrint)}
        </Button>
      </ViewAndPrintBox>);
    }
    else {
        return <></>;
    }
};
export default DownloadAndPrint;

export const TEALIUM_PAGE_VIEW = {
    PAGE_L1: 'MLP',
    PAGE_L2: 'My Lincoln Portal',
    PAGE_L3: {
        // public
        REGISTRATION: 'Registration',
        LOGIN: 'Login',
        MIGRATION_LEARN_MORE: 'migration',
        MIGRATION_VANITY_PAGE: 'vanity page',
        COMM_HELP: 'Communications Terms And Conditions',
        FORGOT_INFO: 'Forgot Info',
        UNAUTHORIZED: 'Unauthorized',
        ENROLLMENT: 'Enrollment',
        VERIFY_EMAIL: 'Verify Email',
        SSO_LOGOUT: 'SSO Logout',
        ACCESSIBILITY: 'Accessibility',
        CONTACT_US: 'Contact Us',
        PAGE_NOT_FOUND: 'Page Not Found',
        MFA: 'Multi factor authentication',
        //ER
        STANDARD_REPORTS: 'Standard Reports',
        CRS: 'Create My Report',
        LCI: 'Life Claim Intake',
        TEAM_VIEW: 'Team View',
        EMAIL_NOTIFICATIONS: 'Email Notifications',
        USER_ACCESS: 'User Access',
        EMPLOYER_FORMS: 'Employer Forms',
        RSC: 'Recent Status Changes',
        CUSTOMER_SEARCH: 'Customer Search',
        EMPLOYER_RESOURCES: 'Employer Resources',
        // EE
        EOI: 'evidence of insurability intake',
        BDM: 'Beneficiary Designation Management',
        EMPLOYEE_FORMS: 'Employee Forms',
        EMPLOYEE_PROFILE: 'Employee Profile',
        EMPLOYEE_CONTACTS: 'Employee Contacts',
        DENTAL: 'dental insurance',
        // Both
        STATUS: 'View Status',
        UNPLANNED_ABSENCE: 'Unplanned Absence',
        GLOSSARY: 'Glossary',
        CLI: 'claim leave intake',
        RIA: 'report intermittent absence',
        HOME_PAGE: 'Home Page',
        RAB: 'Report A Birth',
        USAA: 'USAA',
        MIGRATION: 'migration',
    },
    PAGE_L4: {
        REGISTRATION: {
            REGISTER: 'Register',
            COMPANY_CODE: 'Company Code',
            ADDITIONAL_INFORMATION: 'Additional Information',
        },
        LOGIN: {
            LOGIN: 'Login',
            MFA_REGISTRATION: 'MFA Registration',
            MFA_VERIFICATION: 'MFA Verification',
            FORGOT_PASSWORD_USERNAME: 'Forgot password or username',
        },
        STANDARD_REPORTS: {
            VIEW: 'View',
            FILE_DETAILS: 'File Details',
        },
        CRS: {
            LANDING_PAGE: 'Landing Page',
            FIELD_SELECT: 'Field Select',
            RUN_REPORT: 'Run Report',
            FIELD_DETAIL_PAGE: 'Report Field Detail Page',
            FILTER_REPORT: 'Filter Report',
        },
        LCI: {
            GETTING_STARTED: 'Getting Started',
            EMPLOYER_INFO: 'Employer Info',
            EMPLOYEE_INFO: 'Employee Info',
            POLICY_INFO: 'Policy Info',
            DEPENDENT_INFO: 'Dependent Info',
            CALLER_INFO: 'Caller Info',
            ADDITIONAL_INFO: 'Additional Info',
            REVIEW_AND_SIGN: 'Review and Sign',
            CONFIRMATION: 'Confirmation',
            POLICY_BENEFICIARIES: 'Policy Beneficiaries',
            CANCEL: 'Cancel',
        },
        TEAM_VIEW: {
            VIEW: 'View',
        },
        EMAIL_NOTIFICATIONS: {
            TOGGLE_NOTIFICATIONS: 'Toggle Notifications',
        },
        USER_ACCESS: {
            SEARCH: 'Search',
            USER_PERMISSIONS: 'User Permissions',
        },
        EMPLOYER_FORMS: {
            VIEW: 'View',
        },
        EMPLOYEE_CONTACTS: {
            VIEW: 'View',
        },
        RSC: {
            VIEW: 'View',
            CLAIM_DETAILS: 'Claim Details',
            LEAVE_DETAILS: 'Leave Details',
        },
        EOI: {
            CONFIRMATION: 'confirmation',
        },
        BDM: {
            VIEW: 'View',
            FMI: 'Find my Information',
            ADD_BENEFICIARY: 'Add Beneficiary', // Modal
            ADD_CONTINGENT_BENEFICIARY: 'Add Contingent Beneficiary', // Modal
            CONFIRMATION: 'Confirmation',
        },
        EMPLOYEE_FORMS: {
            VIEW: 'View',
        },
        STATUS: {
            EMPLOYER_LANDING_PAGE: 'Employer Landing Page',
            EMPLOYER_VIEW: 'Employer View',
            EMPLOYEE_VIEW: 'Employee View',
            EMPLOYEE_INFO: 'Employee Info',
            EMPLOYEE_SEARCH: 'Employee Search',
            FIND_MY_INFO: 'Find My Info',
            LEAVE_DETAILS: 'Leave Details',
            CLAIM_DETAILS: 'Claim Details',
            COVERAGE_DETAILS: 'Coverage Details',
            VIEW_CLAIM: 'View Claim',
            VIEW_LEAVE: 'View Leave',
            VIEW_ACCOMMODATION: 'View Accommodation',
        },
        UNPLANNED_ABSENCE: {
            VIEW: 'View',
        },
        GLOSSARY: {
            VIEW_GLOSSARY: 'View glossary',
        },
        CLI: {
            CLI_CONFIRMATION: 'CLI Confirmation',
        },
        CUSTOMER_SEARCH: {
            CUSTOMER_SEARCH_LANDING_PAGE: 'Landing Page',
        },
        HOME_PAGE: {
            HOME_PAGE_EE: 'employee view',
            HOME_PAGE_ER: 'employer view',
        },
        EMPLOYEE_PROFILE: {
            VIEW: 'View',
        },
        EMPLOYER_RESOURCES: {
            VIEW: 'View',
        },
        RAB: {
            VIEW: 'View',
            REPORT: 'Report',
            REVIEW: 'Review',
            SUCCESS: 'Success',
            CONFIRMATION_MODAL: 'Confirmation Modal',
        },
        COMM_HELP: {
            VIEW: 'View',
        },
        FORGOT_INFO: {
            VIEW: 'View',
        },
        UNAUTHORIZED: {
            VIEW: 'View',
        },
        ENROLLMENT: {
            SUCCESS: 'Success',
        },
        VERIFY_EMAIL: {
            VIEW: 'View',
        },
        SSO_LOGOUT: {
            VIEW: 'View',
        },
        ACCESSIBILITY: {
            VIEW: 'View',
        },
        CONTACT_US: {
            VIEW: 'View',
        },
        PAGE_NOT_FOUND: {
            VIEW: 'View',
        },
        USAA: {
            CONFIRMATION: 'Confirmation',
            EMPLOYEE_LOGOUT: 'Employee Logout',
            LOGIN: 'Login',
            TIME_OFF: 'Time Off',
            VERIFY_SUBMISSION: 'Verify Submission',
        },
        MFA: {
            PREFERENCES: {
                VIEW: 'View',
            },
            CONFIRMATION: {
                VIEW: 'View',
            },
        },
    },
    PAGE_L5: {
        STATUS: {
            COVERAGE_DETAILS: 'Claims&Leaves',
        },
        LOGIN: 'login',
        UPDATE_DETAILS: 'update details',
        EMAIL_CONFLICT: 'email clash - confirmation',
        CONFIRMATION: 'confirmation',
        MIGRATION: {
            LEARN_MORE: 'learn more',
            VANITY_PAGE: 'vanity page',
            UPDATE_DETAILS_CONFIRMATION: 'update details - confirmation',
            UPDATE_DETAILS: 'update details',
            USERNAME: 'username',
            USERNAME_CONFIRMATION: 'username - confirmation',
            IDENTIFY_USER: 'Identify user audience',
            MIGRATION_HELP: 'migration - help',
            HERE_TO_HELP: 'we are here to help',
        },
    },
    PAGE_L6: {
        STATUS: {
            DETAILS: 'Details',
        },
    },
    USER_AUDIENCE: {
        EMPLOYEE: 'my lincoln portal employee',
        PUBLIC: 'my lincoln portal public',
    },
};
export const TEALIUM_EVENT = {
    EVENT_ACTION: {
        BLUR: 'blur',
        FOCUS: 'focus',
        ERROR: 'error',
        BUTTON: 'button',
        LINK: 'link',
        MODAL: 'modal',
        UPLOAD: 'upload doc',
        CHANGE: 'change',
        SELECT: 'select',
        SEARCH: 'search',
        VIDEO_ENGAGEMENT: 'video&engagement',
        TAB: 'tab',
        TOGGLE: 'toggle',
        CHECKBOX: 'checkbox',
        ONLOAD: 'on&load',
        SELF_SERVICE: 'selfservice', // dont ask me... i think this makes no sense but i do what im told...
        DOWNLOAD: 'download',
        MIGRATION: 'migration',
        SLIDE_OUT: 'slide-out',
    },
    EVENT_TYPE: {
        // TODO: add events here as we work with Brian Gannon to capture the events we want for each feature.
        REGISTRATION: 'registration',
        LOGIN: 'login',
        LOGOUT: 'logout',
        FORGOT_PASSWORD: 'forgotPassword',
        FORGOT_USERNAME: 'forgotUsername',
        SEARCH: 'search',
        PRINT: 'print',
        CONTACT_SUPPORT: 'contact&support',
        HOME: 'home',
        HOME_PAGE: 'home page',
        PROFILE: 'profile',
        HEADER: 'header',
        FOOTER: 'footer',
        UNPLANNED_ABSENCE: 'unplannedAbsence',
        CUSTOMER_ROLLUP_BAR: 'customer&rollup&bar',
        LANDING_PAGE_BANNER: 'landing&page&banner',
        CONTENT_BLOCKS: 'content&blocks',
        EMPLOYEE_LANDING_PAGE_SEARCH: 'employee&landing&page&search',
        DOCUMENT_UPLOAD_MODAL: 'document&upload&modal',
        DOCUMENT_UPLOAD: 'document&upload',
        USER_PROFILE_INFORMATION: 'user&profile&information',
        REMOVE_USER_MODAL: 'remove&user&modal',
        COPY_PERMISSIONS_MODAL: 'copy user permissions',
        RESET_PASSWORD: 'reset&passowrd',
        TOGGLE_EMAIL_NOTIFICATIONS: 'toggle&email&notifications',
        EMPLOYER_PERMISSIONS_FORM: 'employer&permissions&form',
        EMPLOYER_SUBS_LOCS_MODAL: 'employer&subs&locs&modal',
        CLAIM_LEAVE_INTAKE_SIDE_PANEL: 'claim&leave&intake&side&panel',
        CLAIM_LEAVE_INTAKE: 'claim leave intake',
        ACCOMMODATION_INTAKE: 'accommodation intake',
        REPORT_INTERMITTENT_ABSENCE: 'report intermittent absence',
        ADMIN_USER_SEARCH: 'admin&user&search',
        CREATE_MY_REPORT_FIELD_DETAIL: 'create&my&report&field&detail',
        SUBMIT_ALERTS_NOTIFICATIONS: 'submit&alerts&notifications',
        TOGGLE_TEXT_NOTIFICATIONS: 'display text opt-in toggle',
        VIDEO_ENGAGEMENT: 'video&engagement',
        SIGNATURE_SUBMIT: 'signature&submit',
        FIND_INFORMATION: 'find&information',
        ADD_BENEFICIARY: 'add&beneficiary',
        GET_HELP: 'get&help',
        RUN_REPORT: 'run&report',
        SUBMIT_INFO: 'submit&find&my&info',
        LANDING_PAGE_BUTTON: 'landing&page&button',
        SHOW_CONFIRMATION: 'show&confirmation',
        LIFE_CLAIM_INTAKE: 'life claim intake',
        OPEN_HELP_MODAL: 'open&help&modal',
        HIDE_HELP_MODAL: 'hide&help&modal',
        OPEN_AUTHY_MODAL: 'open&authy&modal',
        MFA_HELP_MODAL: 'mfa&help&modal',
        CLOSE_RAB_VIEW_SUBMISSION: 'close&rab&view&submission',
        VIEW_RAB_SUBMISSION: 'view&rab&submission',
        SHOW_GLOSSARY: 'show&glossary',
        DOWNLOAD_CORRESPONDENCE: 'download&correspondence',
        AS_CORRESPONDENCE: 'as&correspondence',
        AS_NOTES: 'as&notes',
        AS_REQUEST: 'as&request',
        CLAIM_DATES: 'claim&dates',
        PAYMENT_INFORMATION: 'payment&information',
        SHOW_CORRESPONDENCE: 'show&correspondence',
        SHOW_CLAIM_NOTES: 'show&claim&notes',
        DOWNLOAD_CLAIM_DETAILS: 'download&claim&details',
        PAYMENT_EXPAND_TOGGLE: 'payment&expand&toggle',
        DOWNLOAD_LEAVE_DETAILS: 'download&leave&details',
        NOTE_FILTER_APPLY: 'note&filter&apply',
        NOTE_FILTER_CLEAR: 'note&filter&clear',
        NOTE_TOGGLE_EXPAND: 'note&toggle&expand',
        REMOVE_FROM_WATCHLIST: 'remove&from&watchlist',
        WATCHLIST_CHECKBOX: 'watchlist&checkbox',
        EMPLOYEE_OVERVIEW: 'employee&overview',
        REPORTEE: 'reportee',
        MANAGER: 'manager',
        VIEW_TEAM_MEMBER: 'view&team&member',
        FOOTER_CONTAINER_LINK: 'footer&container&link',
        USER_PERMISSIONS: 'user&permissions',
        EMPLOYEE_FORMS: 'employee&forms',
        LANDING_PAGE: 'landing&page',
        MFA: 'multi&factor&authentication',
        MFA_PREFERENCE: 'multi&factor&authentication&preference',
        STANDARD_REPORTS: 'standard&reports',
        EOI_EMP_ID_PRE_POPULATED: 'eoi&employeeid&pre&populated',
        EOI_EMP_ID_ENTERED: 'eoi&employeeid&entered',
        EOI_EMP_ID_EMPTY: 'eoi&employeeid&empty',
        SELECT_CUSTOMER: 'select&customer',
        START_CLI: 'start a claim or leave',
        VIEW_STATUS: 'view status',
        VERIFY_YOUR_IDENTITY: 'verify your identity',
        ACCOMMODATION: 'accommodation',
        ACCOUNT_ACCESS: 'account access',
        ECONSENT: 'econsent',
        MIGRATION: 'migration',
        GP_MIGRATION: 'gp_migration',
        MIGRATION_LOGIN: 'migration - login',
        MIGRATION_HELP: 'migration - help',
        DENTAL_INSURANCE: 'dental insurance',
        DENTAL_DEDUCTIBLES_AND_COINSURANCE: 'deductibles and coinsurance',
        DENTAL_COVERED_SERVICES: 'covered services',
        DENTAL_CLAIMS: 'claims',
        RAB: 'report a birth',
        EOI: 'evidence of insurability intake',
    },
    EVENT_STATUS: {
        START: 'start',
        CANCEL: 'Cancelled',
        COMPLETE: 'complete',
        CONFIRM: 'Confirm',
        OPEN: 'Open',
        SUCCESSFUL_LOGIN: 'Logged In',
        SUBMITTED: 'Submitted',
        DELETED: 'Deleted',
        ERROR: 'Error',
        SUCCESS: 'success',
        EXPAND: 'Expand',
    },
    EVENT_VERSION: {
        ACCOUNT_LOOKUP: 'account lookup',
        CONFIRM_PASSWORD: 'confirm password',
        BLOCK_REGISTRATION: 'block registration',
        USERNAME_CONFIRMATION: 'username - confirmation',
        UPDATE_DETAILS_CONFIRMATION: 'update details - confirmation',
        EMAIL_CONFLICT: 'email clash',
        UPDATE_DETAILS: 'update details',
        USERNAME: 'username',
        FAQ: 'frequently asked questions',
        HOMEPAGE: 'home page',
        IDENTIFY_USER: 'Identify user audience',
        EMPLOYER: 'employer',
        EMPLOYEE: 'employee',
        LANDING_PAGE_BANNER: 'landing&page&banner',
        REPORT: 'report',
        REVIEW: 'review',
        CONFIRMATION: 'confirmation',
        SUBMIT: 'submit'
    },
    EVENT_NAME: {
        FORGOT_PASSWORD: 'forgot password',
        FORGOT_PASSWORD_AND_USERNAME: 'forgot username and password',
        SUBMIT: 'submit',
        LOGIN: 'login',
        LEARN_MORE: 'learn more',
        MIGRATE_MY_ACCOUNT: 'migrate my account',
        REGISTER: 'register now',
        LET_US_HELP: 'let us help',
        MEMBER: 'member',
        SAVE_AND_CONTINUE: 'save and continue'
    },
};

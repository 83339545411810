import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import useEoiContext from '../../hooks/useEoiContext';
import useTranslate from '../../../../hooks/useTranslate';
import { ConfirmationText } from '../../i18n/ConfirmationText';
import Alert from '@digitools/honest-react/dist/lib/components/Alert';
import { EoiGeneralText } from '../../i18n/EoiText';
import YourNextSteps from './components/YourNextSteps';
import OurNextSteps from './components/OurNextSteps';
import EmployerMessage from './components/EmployerMessage';
import ConfirmationMessage from './components/ConfirmationMessage';
import DownloadAndPrint from './components/DownloadAndPrint';
import useTealium from '../../../../hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from '../../../../constants/tealiumConstants';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
import useExperience from 'src/hooks/useExperience';
export const getApprovalMessage = (applicationStatus) => {
    switch (applicationStatus) {
        case 'FULLY_APPROVED': return ConfirmationText.fullyApproved;
        case 'PARTIALLY_APPROVED': return ConfirmationText.partiallyApproved;
        default: return ConfirmationText.otherApproval;
    }
};
const addDateAndTimeToApprovalString = (approvalString) => {
    const date = new Date();
    return approvalString.replace('DATE', date.toLocaleDateString()).replace('TIME', date.toLocaleTimeString());
};
const Confirmation = () => {
    const { questionSubmissionResponse } = useEoiContext();
    const { t } = useTranslate();
    const [downloadError, setDownloadError] = useState();
    const { trackView } = useTealium();
    const { experience } = useExperience();
    const pageL4 = CustomEmployeeText(experience === Experience.EMPLOYEE ? 'employee view' : 'employer view');
    useEffect(() => {
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EOI,
            page_l4: pageL4,
            page_l5: TEALIUM_PAGE_VIEW.PAGE_L4.EOI.CONFIRMATION,
        });
    }, []);
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    const approvalMessage = questionSubmissionResponse && getApprovalMessage(questionSubmissionResponse.applicationStatus);
    return (<div data-public={true}>
        {questionSubmissionResponse && approvalMessage &&
            <>
            <Heading theme={themes.lincoln} elemType={'h1'} color={'black'}>{t(ConfirmationText.evidenceOfConfirmation)}</Heading>
            <div data-testid={'rtmus-response'}>
              <Alert type={'success'} theme={themes.lincoln} style={{ marginTop: '1.375rem' }} data-testid={'submit-success-alert'}>{t(ConfirmationText.success)}</Alert>
              <Heading theme={themes.lincoln} elemType={'h2'} elemStyle={'h3'} color={'primary'} style={{ marginBottom: '1.875rem', marginTop: '2.5rem' }}>{`${t(ConfirmationText.yourEventIdIs)} #${questionSubmissionResponse.applicationId}`}</Heading>
              {questionSubmissionResponse.applicationAccepted
                    ? <><b>{t(EoiGeneralText.thankYou)} </b> {addDateAndTimeToApprovalString(t(approvalMessage))}</>
                    : <><b>{t(EoiGeneralText.thankYou)}</b> {addDateAndTimeToApprovalString(t(ConfirmationText.otherApproval))}</>}
              
              {downloadError &&
                    <Alert type={'error'} theme={themes.lincoln} closeable={true} onClose={() => setDownloadError(undefined)} data-testid={'confirmation-download-error'}>{downloadError}</Alert>}
              <DownloadAndPrint setErrorMessage={setDownloadError}/>
              <YourNextSteps />
              <OurNextSteps approvalMessage={approvalMessage}/>
              <EmployerMessage />
              <ConfirmationMessage approvalMessage={approvalMessage}/>
            </div>
          </>}
      {/* Error/Edge Case - Unknown Submission Status - No RTMUS Response (timeout) */}
      {!questionSubmissionResponse &&
            <>
          <Heading theme={themes.lincoln} elemType={'h1'} color={'black'}>{t(ConfirmationText.evidenceOfSubmission)}</Heading>
          <div data-testid={'no-rtmus-response'} style={{ marginTop: '1.875rem' }}> 
            <span><b>{t(EoiGeneralText.thankYou)} </b> {addDateAndTimeToApprovalString(t(ConfirmationText.unknownApproval))}</span>
            <div style={{ borderLeft: '3px solid ' + themes.lincoln.palette.primary, paddingLeft: '1rem', marginTop: '1.5rem' }}>
              <Heading elemType={'h3'}>{t(ConfirmationText.unknownSubmissionStatusHeader)}</Heading>
              <p>{t(ConfirmationText.unknownSubmissionStatusMessage)}</p>
            </div>
            <DownloadAndPrint setErrorMessage={setDownloadError}/>
            <OurNextSteps approvalMessage={ConfirmationText.unknownApproval}/>
            <EmployerMessage />
            <ConfirmationMessage approvalMessage={ConfirmationText.unknownApproval}/>
          </div>
        </>}
    </div>);
};
export default Confirmation;

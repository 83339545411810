import React, { useContext } from 'react';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import { Form } from 'informed';
import useTranslate from '../../../../hooks/useTranslate';
import { EoiGeneralText } from '../../i18n/EoiText';
import StepperButtons from '../../components/StepperButtons';
import { StepperContext } from '@digitools/honest-react/dist/lib/components/Stepper/StepperContext';
import ApplicantReflexiveQuestions from './ApplicantReflexiveQuestions';
import useEoiContext from '../../hooks/useEoiContext';
import SaveForLaterErrorAlert from '../../components/SaveForLaterErrorAlert';
import ValidationErrorAlert from '../../components/ValidationErrorAlert';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import { QuestionsText } from '../../i18n/QuestionsText';
import LegalFormNumbers from '../../components/LegalFormNumbers';
import { isSitusStateNY } from '../../utils/HelperFunctions';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import useTealium from 'src/hooks/useTealium';
const parseReflexiveResponses = (responses, applicant, dependentId) => {
    const reflexiveResponses = [];
    responses.forEach((response) => {
        // This is the magic sauce for getting the values out of the response object (crazy nested and with checkbox unique identifiers added on)
        const value = response[`value-${applicant}${dependentId ? dependentId : ''}${response.questionCode}`];
        // FYI boxes checked then unchecked will have values of false instead of undefined, not needed on submit so skip them here
        if (value) {
            const additionalQuestions = [];
            if (response.additionalQuestions) {
                response.additionalQuestions.forEach((additionalQuestion) => {
                    let value = [];
                    // If value is an array just add the array.
                    if (Array.isArray(additionalQuestion.value)) {
                        value = additionalQuestion.value;
                    }
                    else {
                        // else if value exists, create an array with that value and use that.
                        if (additionalQuestion.value !== undefined) {
                            value = [additionalQuestion.value];
                        }
                        else {
                            // TODO: probably want to fix Checkbox in honest and remove all this at some point.
                            // Else we're in the case of a multiselect checkbox
                            // values here end up on the form object with a key of format {applicant}{dependentId}-{questionCode}
                            // Grab all the keys.
                            const keys = Object.keys(additionalQuestion);
                            // Filter the keys out to get the one with the format of {applicant}{dependentId}-{questionCode} and values are === to true. (Checkbox adds true/false by default so we only filter on the selected ones.)
                            // @ts-ignore
                            const vals = keys && keys.filter(((key) => (key.indexOf(applicant) > -1) && additionalQuestion[key])); // Get all values with applicant that are equal to true. e.x. employee-59
                            // Make sure the list isn't undefined and actually has values.
                            if (vals && vals.length > 0) {
                                // @ts-ignore
                                value = vals.map(question => {
                                    // split the key from {applicant}{dependentId}-{questionCode} and pop off the question code if the string.split worked.
                                    const parts = question.split('-');
                                    if (parts.length > 0) {
                                        return parts.pop();
                                    }
                                }).filter((x) => x !== undefined); // filter out undefined values
                            }
                        }
                    }
                    const newAdditionalQuestion = {
                        value,
                        questionCode: additionalQuestion.questionCode
                    };
                    additionalQuestions.push(newAdditionalQuestion);
                });
            }
            response = {
                originalQuestionCode: response.originalQuestionCode,
                questionCode: response.questionCode,
                value: [value.toString()],
                additionalQuestions,
            };
            reflexiveResponses.push(response);
        }
    });
    return reflexiveResponses;
};
// TODO: ApplicantContainer should have padding within it by default, can/shoudl remove 1rem margin added on all internal components elsewhere
// TODO: type ReflexiveQuestions on provider
const ReflexiveQualifyingQuestions = ({ goBack }) => {
    const { t } = useTranslate();
    const stepper = useContext(StepperContext);
    const { interviewAnswers, reflexiveQuestions, setReflexiveAnswers, setDisplaySaveForLaterError, setDisplaySaveForLaterModal, setValidationError, initialInformation } = useEoiContext();
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackEvent } = useTealium();
    const savePageToProvider = (values) => {
        // Most of this is needed because you still need to pull back out the  question code & other unique junk from the nested checkbox values inside each scope
        // .... very sad Checkbox breaks same field names in diff scopes.. most of this junk could be avoided
        const reflexiveAnswers = {
            employee: {
                responses: [],
            },
            spouse: {
                responses: [],
            },
            dependents: []
        };
        /***** EMPLOYEE REFLEXIVE QUESTIONS *****/
        // @ts-ignore
        if (values && values.employee && values.employee.responses) {
            // @ts-ignore
            reflexiveAnswers.employee.responses = parseReflexiveResponses(values.employee.responses, 'employee');
        }
        /***** SPOUSE REFLEXIVE QUESTIONS *****/
        // @ts-ignore
        if (values && values.spouse && values.spouse.responses) {
            // @ts-ignore FIXME:delete the uncommented code later
            reflexiveAnswers.spouse.responses = parseReflexiveResponses(values.spouse.responses, 'spouse');
        }
        /***** DEPENDENT REFLEXIVE QUESTIONS *****/
        if (values && values.dependents) {
            // @ts-ignore
            values.dependents.forEach((dependent, i) => {
                const dependentResponses = {
                    dependentId: dependent.dependentId,
                    responses: []
                };
                // @ts-ignore
                dependentResponses.responses = parseReflexiveResponses(dependent.responses, 'dependent', dependent.dependentId);
                reflexiveAnswers.dependents.push(dependentResponses);
            });
        }
        setReflexiveAnswers(reflexiveAnswers);
    };
    const submit = async (values) => {
        trackClickWithPage('continue', '/customer/eoi/qualifyingQuestionsContinued');
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EOI,
            event_name: TEALIUM_EVENT.EVENT_NAME.SAVE_AND_CONTINUE,
            event_version: 'qualifying medical questions continued',
            event_status: 'step 4'
        });
        setValidationError(false);
        setDisplaySaveForLaterError(false);
        setDisplaySaveForLaterModal(false);
        savePageToProvider(values);
        if (stepper.isEditing()) {
            stepper.completeEdit();
        }
        else {
            stepper.next();
        }
    };
    const onSubmitFailure = (errors) => {
        trackClickWithPage('continue', '/customer/eoi/qualifyingQuestionsContinued');
        setValidationError(true);
        window.scrollTo(0, 0);
    };
    const newInstructions = (instructions) => {
        if (instructions && instructions.english) {
            return true;
        }
        return false;
    };
    return (<div data-public={true}>
      <ValidationErrorAlert />
      <Heading elemType={'h1'} color={'primary'} theme={themes.lincoln} data-testid={'welcome-heading'}>
        {isSitusStateNY(initialInformation) ? t(EoiGeneralText.reflexiveStatementOfHealthStep) : t(EoiGeneralText.reflexiveQualifyingQuestionsStep)}
      </Heading>
      <p>{t(QuestionsText.reflexiveQuestionsSubHeading)}</p>
      <Form onSubmit={submit} onSubmitFailure={onSubmitFailure}>
        {reflexiveQuestions && reflexiveQuestions.employee && reflexiveQuestions.employee.length > 0 && interviewAnswers.applicants && interviewAnswers.applicants.employee &&
            <ApplicantReflexiveQuestions applicant='employee' applicantReflexiveQuestions={reflexiveQuestions.employee} firstName={interviewAnswers.applicants.employee.firstName} lastName={interviewAnswers.applicants.employee.lastName} instructions={newInstructions(reflexiveQuestions.questionInstructions?.employee) ? reflexiveQuestions.questionInstructions.employee : reflexiveQuestions.instructions}/>}
        {reflexiveQuestions && reflexiveQuestions.spouse && reflexiveQuestions.spouse.length > 0 && interviewAnswers.applicants && interviewAnswers.applicants.spouse &&
            <ApplicantReflexiveQuestions applicant='spouse' applicantReflexiveQuestions={reflexiveQuestions.spouse} firstName={interviewAnswers.applicants.spouse.firstName} lastName={interviewAnswers.applicants.spouse.lastName} instructions={newInstructions(reflexiveQuestions.questionInstructions?.spouse) ? reflexiveQuestions.questionInstructions.spouse : reflexiveQuestions.instructions}/>}
        {reflexiveQuestions && reflexiveQuestions.dependents && interviewAnswers.applicants && interviewAnswers.applicants.dependents &&
            <>
            {interviewAnswers.applicants.dependents.map((dependent, i) => {
                    const dependentReflexiveQuestionSet = reflexiveQuestions.dependents.filter((questionSet) => questionSet.dependentId === dependent.dependentId).shift();
                    if (dependentReflexiveQuestionSet && dependentReflexiveQuestionSet.questions && dependentReflexiveQuestionSet.questions.length > 0) {
                        return (<ApplicantReflexiveQuestions applicant='dependent' applicantReflexiveQuestions={dependentReflexiveQuestionSet.questions} firstName={dependent.firstName} lastName={dependent.lastName} key={i} dependentIndex={i} dependentId={dependent.dependentId} instructions={newInstructions(reflexiveQuestions.questionInstructions?.dependent) ? reflexiveQuestions.questionInstructions.dependent : reflexiveQuestions.instructions}/>);
                    }
                })}
          </>}
        <SaveForLaterErrorAlert />
        <LegalFormNumbers showSupplementalForm={true}/>
        <StepperButtons continueButtonType={'continue'} backButtonOnClick={goBack} saveToProvider={savePageToProvider} page={'/customer/eoi/qualifyingQuestionsContinued'} analyticsPageName={isSitusStateNY(initialInformation) ? t(EoiGeneralText.reflexiveStatementOfHealthStep) : t(EoiGeneralText.reflexiveQualifyingQuestionsStep)}/>
      </Form>
      <br />
    </div>);
};
export default ReflexiveQualifyingQuestions;

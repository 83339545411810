import React, { useContext, useState, useEffect } from 'react';
import { StepperContext } from '@digitools/honest-react/dist/lib/components/Stepper/StepperContext';
import Heading from '@digitools/honest-react/dist/lib/components/Heading';
import { Form, Scope } from 'informed';
import { ApplicantInformationText } from '../../i18n/ApplicantInformationText';
import useTranslate from '../../../../hooks/useTranslate';
import StepperButtons from '../../components/StepperButtons';
import themes from '@digitools/honest-react/dist/lib/theming/themes';
import Button from '@digitools/honest-react/dist/lib/components/Button';
import Icon from '@digitools/honest-react/dist/lib/components/Icon';
import { v4 } from 'uuid';
import { EoiContext } from '../../providers/EoiProvider';
import ApplicantContainer from '../../components/ApplicantContainer';
import ApplicantInfoForm from './ApplicantInfoForm';
import styled from 'styled-components';
import SaveForLaterErrorAlert from '../../components/SaveForLaterErrorAlert';
import useGoogleAnalytics from '../../../../hooks/useGoogleAnalytics';
import LegalFormNumbers from '../../components/LegalFormNumbers';
import useAuthentication from 'src/hooks/useAuthentication';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_EVENT } from 'src/constants/tealiumConstants';
import { EoiGeneralText } from '../../i18n/EoiText';
const AddDependentButton = styled(Button) `
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 1rem;
`;
const ApplicantInfo = () => {
    const { user } = useAuthentication();
    const { t } = useTranslate();
    const [dependentBoxContent, setDependentBoxContent] = useState([]);
    const [dependentToRemove, setDependentToRemove] = useState(undefined);
    const [dependentScopeIndex, setDependentScopeIndex] = useState(0);
    const stepper = useContext(StepperContext);
    const { interviewAnswers, setApplicants, setDisplaySaveForLaterError, setDisplaySaveForLaterModal, setValidationError } = useContext(EoiContext);
    const { trackClickWithPage } = useGoogleAnalytics();
    const { trackEvent } = useTealium();
    const getEmployeeFromForm = (values) => {
        const employee = { ...values.employee,
            disableBirthState: interviewAnswers.applicants.employee.disableBirthState,
            disableDateOfBirth: interviewAnswers.applicants.employee.disableDateOfBirth,
            disableFirstName: user && user.ssoUser ? false : interviewAnswers.applicants.employee.disableFirstName,
            disableLastName: user && user.ssoUser ? false : interviewAnswers.applicants.employee.disableLastName,
            disableMiddleInitial: interviewAnswers.applicants.employee.disableMiddleInitial,
            disableSex: interviewAnswers.applicants.employee.disableSex,
            disableSocialSecurityNumber: interviewAnswers.applicants.employee.disableSocialSecurityNumber,
            ssnFound: interviewAnswers.applicants.employee.ssnFound,
            //@ts-ignore
            employmentInformation: { ...values.employee.employmentInformation },
            //@ts-ignore
            contactInformation: { ...values.employee.contactInformation } };
        if (employee.employmentInformation) {
            employee.employmentInformation.disableDateOfHire = interviewAnswers.applicants.employee.employmentInformation.disableDateOfHire;
            employee.employmentInformation.disableEmployeeId = interviewAnswers.applicants.employee.employmentInformation.disableEmployeeId;
            employee.employmentInformation.disableOccupation = interviewAnswers.applicants.employee.employmentInformation.disableOccupation;
        }
        else {
            employee.employmentInformation = {
                disableDateOfHire: false,
                disableEmployeeId: false,
                disableOccupation: false,
            };
        }
        return employee;
    };
    const getSpouseFromForm = (values) => {
        if (values.spouse) {
            const spouse = {
                ...values.spouse,
                disableBirthState: false,
                disableDateOfBirth: false,
                disableFirstName: false,
                disableLastName: false,
                disableMiddleInitial: false,
                disableSex: false,
                disableSocialSecurityNumber: false,
            };
            if (interviewAnswers.applicants.spouse) {
                spouse.disableBirthState = interviewAnswers.applicants.spouse.disableBirthState;
                spouse.disableDateOfBirth = interviewAnswers.applicants.spouse.disableDateOfBirth;
                spouse.disableFirstName = user && user.ssoUser ? false : interviewAnswers.applicants.spouse.disableFirstName;
                spouse.disableLastName = user && user.ssoUser ? false : interviewAnswers.applicants.spouse.disableLastName;
                spouse.disableMiddleInitial = interviewAnswers.applicants.spouse.disableMiddleInitial;
                spouse.disableSex = interviewAnswers.applicants.spouse.disableSex;
                spouse.ssnFound = interviewAnswers.applicants.spouse.ssnFound;
                spouse.disableSocialSecurityNumber = interviewAnswers.applicants.spouse.disableSocialSecurityNumber;
            }
            return spouse;
        }
        else {
            return undefined;
        }
    };
    const getDependentsFromForm = (values) => {
        if (values.dependents) {
            let dependents = [];
            // @ts-ignore
            values.dependents.forEach((dependent) => {
                const formDependent = {
                    ...dependent,
                    disableBirthState: false,
                    disableDateOfBirth: false,
                    disableFirstName: false,
                    disableLastName: false,
                    disableMiddleInitial: false,
                    disableSex: false,
                    disableSocialSecurityNumber: false,
                };
                if (interviewAnswers.applicants.dependents && interviewAnswers.applicants.disableAddingDependents) {
                    const interviewDependent = interviewAnswers.applicants.dependents.filter((kid) => formDependent.socialSecurityNumber === kid.socialSecurityNumber).shift();
                    if (interviewDependent) {
                        formDependent.disableBirthState = interviewDependent.disableBirthState;
                        formDependent.disableDateOfBirth = interviewDependent.disableDateOfBirth;
                        formDependent.disableFirstName = user && user.ssoUser ? false : interviewDependent.disableFirstName;
                        formDependent.disableLastName = user && user.ssoUser ? false : interviewDependent.disableLastName;
                        formDependent.disableMiddleInitial = interviewDependent.disableMiddleInitial;
                        formDependent.disableSex = interviewDependent.disableSex;
                        formDependent.ssnFound = interviewDependent.ssnFound;
                        formDependent.disableSocialSecurityNumber = interviewDependent.disableSocialSecurityNumber;
                    }
                }
                dependents.push(formDependent);
            });
            return dependents;
        }
        else {
            return undefined;
        }
    };
    const savePageToProvider = (values) => {
        setApplicants({
            disableAddingDependents: interviewAnswers.applicants.disableAddingDependents,
            // @ts-ignore
            employee: getEmployeeFromForm(values),
            // @ts-ignore
            spouse: getSpouseFromForm(values),
            // @ts-ignore
            dependents: getDependentsFromForm(values),
        });
    };
    const submit = async (values) => {
        setValidationError(false);
        trackEvent({
            event_action: TEALIUM_EVENT.EVENT_ACTION.SELF_SERVICE,
            event_type: TEALIUM_EVENT.EVENT_TYPE.EOI,
            event_name: TEALIUM_EVENT.EVENT_NAME.SAVE_AND_CONTINUE,
            event_version: 'applicant information',
            event_status: 'step 3'
        });
        setDisplaySaveForLaterError(false);
        setDisplaySaveForLaterModal(false);
        savePageToProvider(values);
        if (stepper.isEditing()) {
            stepper.completeEdit();
        }
        else {
            stepper.next();
        }
    };
    const onSubmitFailure = (errors) => {
        trackClickWithPage('continue', '/customer/eoi/applicantInfo');
        setValidationError(true);
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        if (interviewAnswers.applicants.dependents && interviewAnswers.applicants.dependents.length > 0) {
            setDependentScopeIndex(interviewAnswers.applicants.dependents.length);
            addExistingDependents();
        }
        else {
            setDependentBoxContent([dependentContent(v4(), false, undefined)]);
        }
    }, []);
    useEffect(() => {
        renderDependentContent();
    }, [dependentBoxContent]);
    useEffect(() => {
        if (dependentToRemove) {
            removeDependent(dependentToRemove);
            renderDependentContent();
            setDependentToRemove(undefined);
        }
    }, [dependentToRemove]);
    const addExistingDependents = () => {
        interviewAnswers.applicants.dependents.forEach((dependent, indexValue) => {
            if (indexValue === 0) {
                setDependentBoxContent([dependentContent(dependent.dependentId ? dependent.dependentId : v4(), false, dependent, indexValue + 1)]);
            }
            else {
                setDependentBoxContent(prev => [...prev, dependentContent(dependent.dependentId, !interviewAnswers.applicants.disableAddingDependents, dependent, indexValue + 1)]);
            }
        });
    };
    const removeDependent = (uuid) => {
        const index = dependentBoxContent.map(i => i.key).indexOf(uuid);
        if (index > -1) {
            const removed = dependentBoxContent.filter(i => i.key !== uuid);
            setDependentBoxContent(removed);
        }
    };
    const addDependentClickEvent = () => {
        const uuid = v4();
        trackClickWithPage('addDependent', '/customer/eoi/applicantInfo');
        setDependentBoxContent(prev => [...prev, dependentContent(uuid, true, undefined)]);
    };
    // dependentNumber is only passed for existing/saved dependents
    const dependentContent = (uuid, removable, dependent, dependentNumber) => {
        let scopeIndex;
        if (dependentNumber) {
            scopeIndex = dependentNumber;
        }
        else {
            scopeIndex = dependentScopeIndex + 1;
            setDependentScopeIndex(dependentScopeIndex + 1);
        }
        return (<div key={uuid}>
        {/* @ts-ignore */}
        <Scope scope={`dependents[${scopeIndex}]`}>
          <ApplicantInfoForm type={'dependent'} interviewAnswersApplicant={dependent} setDependentToRemove={removable ? setDependentToRemove : undefined} uuid={uuid}/>
        </Scope>
      </div>);
    };
    const renderDependentContent = () => {
        return (<div>
        {dependentBoxContent}
        {!interviewAnswers.applicants.disableAddingDependents && (<AddDependentButton theme={themes.lincoln} type={'button'} onClick={() => addDependentClickEvent()}>
            <Icon name={'add-something'} color={'secondary'} size={'xs'} style={{ marginRight: '0.375rem' }}/>
            {t(ApplicantInformationText.addDependent)}
          </AddDependentButton>)}
      </div>);
    };
    return (interviewAnswers.coverageElections ? (<Form onSubmit={submit} onSubmitFailure={onSubmitFailure} data-public={true} data-testid='applicant-info-form-id'>
          <Heading data-testid='applicant-information-heading' theme={themes.lincoln} elemType={'h1'} elemStyle={'h2'} color={'primary'} style={{ marginBottom: '.5rem' }}>
            {t(ApplicantInformationText.headerText)}
          </Heading>
          <ApplicantContainer applicant={'employee'} type={'information'} showEditButton={true}>
            {/* @ts-ignore */}
            <Scope scope={'employee'}>
              <ApplicantInfoForm type={'employee'} interviewAnswersApplicant={interviewAnswers.applicants.employee}/>
            </Scope>
          </ApplicantContainer>
          {interviewAnswers.coverageElections.spouseCoverage && (interviewAnswers.coverageElections.spouseCoverage.length > 0 || interviewAnswers.applicantTypes.spouse) && (<ApplicantContainer applicant={'spouse'} type={'information'} showEditButton={true} startCollapsed={true}>
              {/* @ts-ignore */}
              <Scope scope={'spouse'}>
                <ApplicantInfoForm type={'spouse'} interviewAnswersApplicant={interviewAnswers.applicants.spouse}/>
              </Scope>
            </ApplicantContainer>)}
          {interviewAnswers.coverageElections.dependentCoverage && (interviewAnswers.coverageElections.dependentCoverage.length > 0 || interviewAnswers.applicantTypes.dependent) && (<ApplicantContainer applicant={'dependent'} type={'information'} showEditButton={true} startCollapsed={true}>
              {renderDependentContent()}
            </ApplicantContainer>)}
          <LegalFormNumbers />
          <SaveForLaterErrorAlert />
          <StepperButtons continueButtonType={'continue'} saveToProvider={savePageToProvider} page={'/customer/eoi/applicantInfo'} analyticsPageName={t(EoiGeneralText.applicantInfoStep)}/>
        </Form>) : <></>);
};
export default ApplicantInfo;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Col from '@digitools/honest-react/dist/lib/components/Col';
import Row from '@digitools/honest-react/dist/lib/components/Row';
import Stepper from '@digitools/honest-react/dist/lib/components/Stepper';
import { Spinner } from '@digitools/honest-react';
import EoiVerification from './pages/verification/EoiVerification';
import { EoiGeneralText } from './i18n/EoiText';
import useTranslate from '../../hooks/useTranslate';
import ApplicantCoverage from './pages/applicantCoverage/ApplicantCoverage';
import ApplicantInfo from './pages/applicantInformation/ApplicantInfo';
import useAuthentication from '../../hooks/useAuthentication';
import { GetCustomerConfig, GetLegalText } from './api/EoiApiCalls';
import ReviewAndSignContainer from './pages/reviewAndSign/ReviewAndSignContainer';
import NeedHelpBox from './components/NeedHelpBox';
import StatementOfHealthContainer from './pages/QualifyingQuestions/StatementOfHealthContainer';
import useEoiContext from './hooks/useEoiContext';
import Confirmation from './pages/confirmation/Confirmation';
import { isSitusStateNY } from './utils/HelperFunctions';
import EoiHeadings from './components/EoiHeadings';
import { getFeatureSplashByFunctionalArea } from 'src/api/FeatureSplashApi';
import FeatureSplashNotification from 'src/components/Notifications/FeatureSplashNotification';
import useExperience from 'src/hooks/useExperience';
import { Language } from 'src/types/Language';
import Notifications, { FunctionalArea } from 'src/components/Notifications/Notifications';
import GenericErrorPage from 'src/components/errorPages/GenericErrorPage';
import { useNavigate } from 'react-router-dom';
import useTealium from 'src/hooks/useTealium';
import { TEALIUM_PAGE_VIEW } from 'src/constants/tealiumConstants';
import { CustomEmployeeText } from 'src/utils/CustomExperienceText';
import { Experience } from 'src/types/Experience';
const EoiInterviewContainer = () => {
    const { trackView } = useTealium();
    const navigate = useNavigate();
    const { t } = useTranslate();
    const { authHeader, user } = useAuthentication();
    const { changeLanguage, language, experience } = useExperience();
    const [accessLoading, setAccessLoading] = useState(true);
    // TODO: MOVE TO CONTEXT!
    const [isVerified, setIsVerified] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isOnGettingStartedPage, setIsOnGettingStartedPage] = useState(true);
    const [page, setPage] = useState('/customer/eoi/verification');
    const { eoiError, setEoiError, setCustomerConfig, setLegalText, initialInformation, setIsVerificationPage, isVerificationPage, } = useEoiContext();
    const [splashNotification, setSplashNotification] = useState({
        enabled: false,
        titleEN: '',
        msgEN: '',
        id: 0,
    });
    const stepTitles = [
        t(EoiGeneralText.applicantCoverageStep),
        t(EoiGeneralText.applicantInfoStep),
        isSitusStateNY(initialInformation)
            ? t(EoiGeneralText.statementOfHealthStep)
            : t(EoiGeneralText.qualifyingQuestionsStep),
        t(EoiGeneralText.reviewAndSubmitStep),
    ];
    const legalTextGet = GetLegalText();
    const hasAccessCall = async () => {
        try {
            const response = await axios.get(`/eoi-service/new-interview`, {
                headers: { Authorization: authHeader() },
            });
            if (!response.data) {
                window.location.assign('/eoi');
            }
        }
        catch (error) {
            setEoiError(true);
        }
    };
    const checkFeatureSplash = async () => {
        try {
            const response = await getFeatureSplashByFunctionalArea('eoi', authHeader());
            if (!response.enabled || user?.userInternal) {
                await hasAccessCall();
            }
            setSplashNotification(response);
        }
        catch (error) {
            await hasAccessCall();
        }
        setAccessLoading(false);
    };
    useEffect(() => {
        if (legalTextGet && legalTextGet.data) {
            setLegalText(legalTextGet.data);
        }
    }, [legalTextGet]);
    const pageL4 = CustomEmployeeText(experience === Experience.EMPLOYEE ? 'employee view' : 'employer view');
    useEffect(() => {
        checkFeatureSplash();
        trackView({
            page_l3: TEALIUM_PAGE_VIEW.PAGE_L3.EOI,
            page_l4: pageL4,
        });
    }, []);
    useEffect(() => {
        if (!isOnGettingStartedPage) {
            setIsVerificationPage(false);
            setPage('/customer/eoi/verification');
        }
        else if (isOnGettingStartedPage) {
            setIsVerificationPage(true);
        }
    }, [isOnGettingStartedPage, isVerificationPage]);
    const scrollToTop = (step) => {
        const pages = [
            '/customer/eoi/coverage',
            '/customer/eoi/applicantInfo',
            '/customer/eoi/qualifyingQuestions',
            '/customer/eoi/review',
        ];
        setPage(pages[step]);
        window.scrollTo(0, 0);
    };
    const { loading: configLoading, data: configData, error: configError } = GetCustomerConfig();
    if (configError) {
        setEoiError(true);
    }
    if (configData) {
        setCustomerConfig(configData);
    }
    const situsStateNY = isSitusStateNY(initialInformation);
    useEffect(() => {
        if (situsStateNY && language !== Language.ENGLISH) {
            changeLanguage(Language.ENGLISH);
        }
    }, [language, situsStateNY]);
    return (<>
      {(accessLoading || configLoading) && (<div style={{ margin: 'auto' }} data-testid='initial-load-spinner'>
          <Spinner id='eoi-interview-container-spinner'/>
        </div>)}

      {!accessLoading && !configLoading && !isSubmitted && (<>
          <Notifications data-testid='eoi-notification-banner' functionalArea={FunctionalArea.EOI}/>
          <EoiHeadings isOnGettingStartedPage={isOnGettingStartedPage} isSitusStateNY={situsStateNY}/>
        
          {splashNotification?.enabled && <FeatureSplashNotification notification={splashNotification}/>}
          {eoiError && <div data-testid={'eoi-error-page'} data-public={true}><GenericErrorPage /></div>}
          {(!splashNotification?.enabled || user?.userInternal) &&
                <Row style={{ marginTop: 0 }}>
            {!eoiError && configData && (<>
                <Col sm={12} md={12} lg={9}>
                  {isOnGettingStartedPage && <EoiVerification setIsVerified={setIsVerified} isVerified={isVerified} setIsOnGettingStartedPage={setIsOnGettingStartedPage} configData={configData}/>}
                  {!isOnGettingStartedPage && isVerified && (
                        // @ts-ignore
                        <Stepper stepTitles={stepTitles} data-testid={'eoi-stepper'} onStepChange={scrollToTop} data-public={true}>
                      <ApplicantCoverage setIsOnGettingStartedPage={setIsOnGettingStartedPage}/>
                      <ApplicantInfo />
                      <StatementOfHealthContainer />
                      <ReviewAndSignContainer setIsSubmitted={setIsSubmitted}/>
                    </Stepper>)}
                </Col>
                <Col sm={12} md={12} lg={3}>
                  <NeedHelpBox configData={configData} page={page}/>
                </Col>
              </>)}
          </Row>}
          
        </>)}
      {isSubmitted && <Confirmation />}
    </>);
};
export default EoiInterviewContainer;
